import ContentfulAPI from "../integration/ContentfulAPI"
import Vue from "vue"
// import ContentfulGQL from "../integration/ContentfulGQL"

import {
  globalDiscountSources,
  productTypes,
  promoTypes
  /* promoMatchableProperties,
  discountTypes */
} from "./constants"

import { Bleach } from "@/main.js"

const contentStore = {
  namespaced: true,

  state: {
    currentDate: Date.now(),
    contentModels: null,
    hasFullContent: false,
    isCurrentPageContentLoaded: false,
    currentPageImageManifest: [],
    // currentPageImageLoadedCount: 0,

    globalDiscount: null,

    // sourceMode is for content editors to find references
    sourceMode: false
  },

  mutations: {
    SET_CONTENT: (state, _content) => {
      state.contentModels = { ..._content }
    },

    SET_CONTENT_TYPE: (state, { _contentModel, _data }) => {
      Vue.set(state.contentModels, _contentModel, _data)
    },

    // SET_HAS_FULL_CONTENT: (state, _val) => {
    //   state.hasFullContent = _val
    // },

    SET_CURRENT_PAGE_IS_LOADED(state, _val) {
      state.isCurrentPageContentLoaded = _val
    },
    SET_IMAGE_MANIFEST(state, _list) {
      state.currentPageImageManifest = _list
    },

    SET_GLOBAL_DISCOUNT(state, _val) {
      state.globalDiscount = _val
    },

    SET_SOURCE_MODE(state, _val) {
      state.sourceMode = _val
    },

    SET_PATCH_WAITLIST_ON_SET(state, { _id, _waitlistVal }) {
      // Overwrites the existing waitlist value returned from Bleach API
      state.contentModels.ProductBundle[_id].waitlist = _waitlistVal
    }
  },
  actions: {
    async INIT_CONTENT({ dispatch, commit }) {
      dispatch("locale/INIT_LOCALE", null, { root: true })

      // fetch the core content for the path
      dispatch("FETCH_CONTENT").finally(() => {
        // TODO: Page load idle should ideally fire AFTER all components have settled
        commit("dieselgate/SET_PAGE_LOAD_IDLE", true, {
          root: true
        })
      })
    },

    FETCH_CONTENT({ commit, dispatch, rootState, getters }) {
      return ContentfulAPI.getContent(
        rootState.locale.locale,
        rootState.locale.shopName
      ).then(content => {
        commit("SET_CONTENT", content)

        // Declare any global discount to a dedicated store prop - TODO:
        if (
          getters.GET_SITE_CONFIG.saleAdjustmentValue &&
          getters.GET_SITE_CONFIG.saleCouponCode
        ) {
          commit("SET_GLOBAL_DISCOUNT", {
            discountSource: "CONTENT",
            discountCode: getters.GET_SITE_CONFIG.saleCouponCode,
            discountValue: getters.GET_SITE_CONFIG.saleAdjustmentValue
          })
        }

        // PP-1895 Interim support for dynamic waitlisting on sets
        dispatch("PATCH_SETS_WAITLIST")

        commit("locale/SET_CURRENCY", getters.GET_SITE_CONFIG.currency, {
          root: true
        })
      })
    },

    // PP-1895 Interim support for dynamic waitlisting on sets
    PATCH_SETS_WAITLIST({ state, commit }) {
      // get the sets
      for (const [id, set] of Object.entries(
        state.contentModels.ProductBundle
      )) {
        // for each set, get the constituent products
        const constituentProducts = set.products
          .map(
            product =>
              product &&
              product.sys &&
              state.contentModels.Product[product.sys.id]
          )
          .filter(product => !!product)
        // if any of the products have a waitlist status of 'true', apply that to the set
        constituentProducts.forEach(product => {
          if (product.waitlist == true) {
            commit("SET_PATCH_WAITLIST_ON_SET", {
              _id: id,
              _waitlistVal: true
            })
          }
        })
      }
    },

    // async RENEW_SITE_CONFIG({state,commit}) {
    //   let _newConfig =
    // },

    APPEND_IMAGE_MANIFEST({ state, commit }, payload) {
      return new Promise(resolve => {
        if (process.env.VUE_APP_TRACKING_ENV != "development") {
          const _extantImage = state.currentPageImageManifest.findIndex(
            image =>
              image.src == payload.src && image.imageUuid == payload.imageUuid
          )
          if (_extantImage == -1) {
            Bleach.debug.log("Image Manifest ADD -", payload)
            commit(
              "SET_IMAGE_MANIFEST",
              state.currentPageImageManifest.concat(payload)
            )
            resolve(true)
          } else {
            resolve(false)
          }
        }
      })
    },

    CLEAR_LOADED_IMAGE_FROM_MANIFEST({ state, commit }, { src, imageUuid }) {
      return new Promise(resolve => {
        if (process.env.VUE_APP_TRACKING_ENV != "development") {
          if (
            state.currentPageImageManifest.findIndex(
              image => image.src == src && image.imageUuid == imageUuid
            ) >= 0
          ) {
            Bleach.debug.log("Image Manifest CLEAR -", { imageUuid, src })
            commit(
              "SET_IMAGE_MANIFEST",
              state.currentPageImageManifest.filter(
                image => image.src !== src && image.imageUuid !== imageUuid
              )
            )
            resolve(true)
          } else {
            Bleach.debug.warn(
              "!! Image not in manifest, can't be cleared",
              src,
              imageUuid
            )
            resolve(false)
          }
        }
      })
    },

    SUBMIT_GLOBAL_DISCOUNT({ state, commit }, discountObject) {
      // This selectively applies a new global discount ONLY if it is a higher value discount
      if (
        !state.globalDiscount ||
        (state.globalDiscount.discountSource !==
          globalDiscountSources.CONTENT &&
          discountObject &&
          discountObject.discountValue > state.globalDiscount.discountValue)
      ) {
        commit("SET_GLOBAL_DISCOUNT", discountObject)
      }
    }

    // UPDATE_IMAGE_MANIFEST_PRIORITY({ state, commit }, payload) {
    //   if (process.env.VUE_APP_TRACKING_ENV != "development") {
    //     // find the entry with the same src and add viewport value
    //     let _updatedManifest = state.currentPageImageManifest.map(item =>
    //       item.imageUuid === payload.imageUuid ? { ...item, ...payload } : item
    //     )
    //     commit("SET_IMAGE_MANIFEST", _updatedManifest)
    //   }
    // }
  },

  getters: {
    GET_IS_CORE_CONTENT_READY: state => state.contentModels !== null,

    GET_IMAGES_ARE_LOADED: state => {
      // TODO: Resolve the reasons why 'loaded' count can sometimes be larger than the total image count - likely because of responsive src changes
      return (
        process.env.VUE_APP_TRACKING_ENV == "development" ||
        state.currentPageImageManifest.length === 0
      )
    },

    GET_VIEWPORT_IMAGES_ARE_LOADED: state => {
      return (
        process.env.VUE_APP_TRACKING_ENV == "development" ||
        state.currentPageImageManifest.length === 0
      )
      // return (
      //   process.env.VUE_APP_TRACKING_ENV == "development" ||
      //   state.currentPageImageManifest.filter(image => image.viewport === true)
      //     .length === 0
      // )
    },

    GET_SITE_CONFIG: state => {
      const config = Object.values(state.contentModels.SiteConfig)[0]
      return config
    },

    GET_PRODUCT_ENTRY: (state, getters) => (
      id,
      type = Object.values(productTypes)
    ) => {
      return getters.GET_ENTRY_BY_TYPE(id, type)
    },

    GET_ENTRY_BY_TYPE: state => (id, type = null) => {
      if (type && !Array.isArray(type)) {
        // Find the entry within the type collection supplied
        const entry = state.contentModels[type] && state.contentModels[type][id]
        return entry
      } else {
        // Run through ALL contentModels to find one that has been associated
        let _typeList = Array.isArray(type)
          ? type
          : Object.keys(state.contentModels)
        for (const _type of _typeList) {
          if (state.contentModels[_type] && state.contentModels[_type][id]) {
            const entry = state.contentModels[_type][id]
            return entry
          }
        }
        return
      }
    },

    GET_FIND_ENTRY: (state, getters) => (type, func) => {
      const entry =
        state.contentModels[type] &&
        Object.values(state.contentModels[type]).find(func)
      return entry
        ? getters.GET_ENTRY_BY_TYPE(entry.id || entry.cmsId, type)
        : null
    },

    GET_FILTER_ENTRIES: (state, getters) => (type, func = () => true) => {
      // filter entries of a specific content type. If no function passed in, return all of type
      const entries =
        state.contentModels[type] &&
        Object.values(state.contentModels[type]).filter(func)
      return entries.map(entry =>
        getters.GET_ENTRY_BY_TYPE(entry.id || entry.cmsId, type)
      )
    },

    GET_PRODUCT_BY_ECOM_ID: state => ecomVariantId => {
      return [
        ...Object.values(state.contentModels.Product),
        ...Object.values(state.contentModels.ProductBundle),
        ...Object.values(state.contentModels.ProductGiftCard)
      ].find(product => product.ecomVariantId == ecomVariantId)
    },

    GET_PROMO_DATA_BY_PRODUCT: (
      state,
      getters,
      rootState,
      rootGetters
    ) => productId => {
      // Returns the first 'MATCH' promo that relates to the product.
      // Hierarchy: Product(s) --> Range --> etc (other match properties TBC)

      const _product = getters.GET_PRODUCT_ENTRY(
        productId,
        productTypes.PRODUCT
      )

      let promoData = {
        promo: null,
        getQualifyingQuantityInCart: null,
        getShortfall: null,
        getIsTriggered: false
      }

      // get the promotions from siteConfig
      const _promotions = getters.GET_SITE_CONFIG.promotionsCollection.items

      promoData.promo = _promotions.find(promo => {
        // 1. Return the first promo that includes ths product in its matchProductsCollection field
        if (
          promo.promotionType == promoTypes.MATCH &&
          promo.matchProperty == "PRODUCT" &&
          promo.matchProductsCollection &&
          promo.matchProductsCollection.items
        ) {
          return promo.matchProductsCollection.items
            .map(item => {
              return item && item.__typename === "Group"
                ? item.itemsCollection.items
                : item
            })
            .flat()
            .filter(item => !!item)
            .map(product => product.sys.id)
            .includes(productId)
        }
        // OR
        // 2. Return the first promo that has a selected 'range' which matches the product
        if (
          promo.promotionType == promoTypes.MATCH &&
          promo.matchProperty == "RANGE" &&
          promo.matchList
        ) {
          return _product && promo.matchList.includes(_product.range)
        }

        return null
      })

      if (promoData.promo) {
        promoData.getQualifyingQuantityInCart = (() => {
          let _qualifyingLineItems, _totalQualifyingQuantity
          if (
            promoData.promo.promotionType == promoTypes.MATCH &&
            promoData.promo.matchProperty == "PRODUCT"
          ) {
            _qualifyingLineItems = rootGetters["cart/getLineItems"].filter(
              lineItem => {
                return promoData.promo.matchProductsCollection.items
                  .map(item => {
                    return item && item.__typename === "Group"
                      ? item.itemsCollection.items
                      : item
                  })
                  .flat()
                  .filter(item => !!item)
                  .map(product => product.sys.id)
                  .includes(lineItem.productId)
              }
            )
          }
          if (
            promoData.promo.promotionType == "MATCH" &&
            promoData.promo.matchProperty == "RANGE"
          ) {
            _qualifyingLineItems = rootGetters["cart/getLineItems"].filter(
              lineItem => {
                // console.log("promo lineItem?", lineItem)
                return promoData.promo.matchList.includes(
                  lineItem.product.range
                )
              }
            )
          }
          // sum the quantities from the lineItems
          _totalQualifyingQuantity = _qualifyingLineItems
            .map(lineItem => lineItem && lineItem.quantity)
            .reduce((a, b) => a + b, 0)
          return _totalQualifyingQuantity
        })()
        promoData.getShortfall =
          promoData.promo.threshold - promoData.getQualifyingQuantityInCart

        if (promoData.getShortfall <= 0) {
          promoData.getIsTriggered = true
        }
      }

      return promoData
    },

    GET_HAS_GLOBAL_PRICE_ADJUSTMENT: state => {
      return !!(
        state.globalDiscount &&
        state.globalDiscount.discountCode &&
        state.globalDiscount.discountValue
      )
    }
  }
}

export default contentStore
